// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const BASE_URL = '/api/';
const BASE_MEDIA = '/media/';
const FIXED_MEDIA = 'http://social.es/media/';

export const BASE_WEB = '/home';

export const environment = {
  version: require('../../package.json').version,
  production: false,
  url: {
    base: BASE_URL,
    front: BASE_URL + '/front',
    media: BASE_MEDIA,
    fixedMedia: FIXED_MEDIA
  },
  languages: ['ES', 'EN']
};


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
