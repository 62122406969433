import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationStart } from '@angular/router';
import { BASE_WEB } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Social';
  base_web = BASE_WEB;

  constructor(translateService: TranslateService, private router: Router) {
    translateService.addLangs(['en', 'es']);
    translateService.setDefaultLang('es');
    translateService.use('es');

    router.events.subscribe((val) => {
      // see also
      if (val instanceof NavigationStart) {
        const regexp = new RegExp('home/.+$');
        if (regexp.test(val.url)) {
          $('body').css({'height': '100%'});
        } else {  $('body').css({'height' : 'unset'}); }
      }
  });
  }
}
