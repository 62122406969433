import { Component, OnInit } from '@angular/core';
import { BASE_WEB } from '../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  today: number = Date.now();
  base_web: string;

  constructor(private router: Router) {}

  ngOnInit() {
    this.base_web = BASE_WEB;
  }

  isHome(): boolean {
    const regexp = new RegExp('^' + this.base_web + '/.*$');
    if (regexp.test(this.router.url)) { return false;
    } else { return true; }
  }

}
