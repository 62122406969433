import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxPageScrollModule } from 'ngx-page-scroll';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

// Services
import { WINDOW_PROVIDERS } from './services/windows.service';
import { LandingFixService } from '../shared/services/landing-fix.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { WebpackTranslateLoader } from '../webpack-translate-loader';
import { HttpClientModule } from '@angular/common/http';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import { NgxCaptchaModule } from 'ngx-captcha';

@NgModule({
  exports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    TranslateModule,
    HttpClientModule,
    MatSnackBarModule,
    MatDialogModule,
    NgxCaptchaModule,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxPageScrollModule,
    HttpClientModule,
    MatSnackBarModule,
    MatDialogModule,
    NgxCaptchaModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
        // useFactory: (createTranslateLoader),
        // deps: [HttpClient]
      }
    }),
  ],
  declarations: [
    HeaderComponent,
    FooterComponent
  ],
  providers: [
    WINDOW_PROVIDERS,
    LandingFixService
  ]
})
export class SharedModule { }
