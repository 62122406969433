import { Component, HostListener, Inject, OnInit, isDevMode, ɵConsole } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { WINDOW } from '../services/windows.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
// import { BASE_WEB } from '../../../environments/environment';
import { flags } from '../models/flags';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public darkHeader = false;
  public redHeader = false;
  public menuItems: any[];
  public language = 'es';
  public currentLanguageIndex = 0;
  // base_web: string;
  flag: any;

  // Inject Document object
  constructor(
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window,
    private router: Router
  ) { }

  ngOnInit() {
    $.getScript('./assets/js/script.js');
    $.getScript('./assets/js/tilt.jquery.js');
    this.language = this.translateService.currentLang;
    if (this.router.url !== '/home') {
      this.redHeader = true;
    }
    // this.base_web = BASE_WEB;
    this.flag = flags();
  }


  // @HostListener Decorator
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    if (number >= 500) {
      this.darkHeader = true;
    } else {
      this.darkHeader = false;
    }
  }

  changeLanguage() {
    // get other languages
    const languages = this.translateService.getLangs();
    const current = this.translateService.currentLang;
    this.currentLanguageIndex = languages.findIndex(item => item === current);
    this.translateService.use(languages[(this.currentLanguageIndex + 1) % languages.length]);
    this.language = languages[(this.currentLanguageIndex + 1) % languages.length];
    const prueba = document.getElementById('flagId') as HTMLImageElement;
    const lan = this.language.toUpperCase();
    prueba.src = this.flag[lan];
  }
  currentLanguage() {
    return this.translateService.currentLang.toUpperCase();
  }

  isHome(): boolean {
    const regexp = new RegExp('^/home/?(#.*)?$');
    if (regexp.test(this.router.url)) { return true;
    } else { return false; }
  }


}
